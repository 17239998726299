import {Epic} from 'redux-observable';
import {createRequestEpic} from 'front-core';
import {ActionKey} from '../../constants/action-key';
import HttpClient from '../../services/http-client.service';
import {MetricTreeActionType} from './metric-tree.actions';
import {modelUpdated, notifyEvent} from '../core/core.actions';
import {ModelKey} from '../../constants/model-key';
import {metricToastCreator} from '../toasts.actions';
import {AmplitudeEvent} from '../../constants/amplitude-event';
import {demoProductLimitedActionsFilter} from '../store.utils';
import {
  addInputMetricNetworkRequest,
  addMetricToTreeNetworkRequest,
  removeInputMetricNetworkRequest,
  removeMetricToTreeNetworkRequest,
  setKPITreeMetricPositionsNetworkRequest,
} from '../../http/metric-tree.network-requests';
import {showToastMessage} from '../interface/interface.actions';
import {ToastType} from '../../objects/system/toast-type.enum';

export const addInputMetricEpic: Epic = createRequestEpic(
  {
    types: [MetricTreeActionType.ADD_INPUT_METRIC],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.ADD_INPUT_METRIC,
    request: addInputMetricNetworkRequest,
    onSuccess: (_, payload) => [
      notifyEvent(AmplitudeEvent.INPUT_METRIC_ADDED, {
        id: payload.metricId,
      }),
      modelUpdated({id: payload.metricId}, ModelKey.INPUT_METRIC),
    ],
    onError: err => [metricToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

export const removeInputMetricEpic: Epic = createRequestEpic(
  {
    types: [MetricTreeActionType.REMOVE_INPUT_METRIC],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.REMOVE_INPUT_METRIC,
    request: removeInputMetricNetworkRequest,
    onSuccess: (_, payload) => [
      notifyEvent(AmplitudeEvent.INPUT_METRIC_REMOVED, {
        id: payload.metricId,
      }),
      modelUpdated({id: payload.metricId}, ModelKey.INPUT_METRIC),
    ],
    onError: err => [metricToastCreator('UPDATE_ERROR')],
  },
  HttpClient
);

export const setKPITreeMetricPositionsEpic: Epic = createRequestEpic(
  {
    types: [MetricTreeActionType.SET_KPI_TREE_METRIC_POSITIONS],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.SET_KPI_TREE_METRIC_POSITIONS,
    request: setKPITreeMetricPositionsNetworkRequest,
    onSuccess: (_, payload) => [notifyEvent(AmplitudeEvent.METRIC_TREE_POSITIONS_CHANGED)],
    onError: err => [showToastMessage('Error updating KPI tree', ToastType.ERROR)],
  },
  HttpClient
);

export const addMetricToTreeEpic: Epic = createRequestEpic(
  {
    types: [MetricTreeActionType.ADD_METRIC_TO_TREE],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.ADD_METRIC_TO_TREE,
    request: addMetricToTreeNetworkRequest,
    onSuccess: (_, payload) => [
      notifyEvent(AmplitudeEvent.METRIC_ADDED_TO_TREE, {
        id: payload.metricId,
      }),
    ],
    onError: err => [showToastMessage('Error adding KPI', ToastType.ERROR)],
  },
  HttpClient
);

export const removeMetricToTreeEpic: Epic = createRequestEpic(
  {
    types: [MetricTreeActionType.REMOVE_METRIC_FROM_TREE],
    filter: demoProductLimitedActionsFilter,
    actionKey: ActionKey.REMOVE_METRIC_FROM_TREE,
    request: removeMetricToTreeNetworkRequest,
    onSuccess: (_, payload) => [
      notifyEvent(AmplitudeEvent.METRIC_REMOVED_FROM_TREE, {
        id: payload.metricId,
      }),
    ],
    onError: err => [showToastMessage('Error removing KPI', ToastType.ERROR)],
  },
  HttpClient
);
